<template>
  <div>
    <div class="title">
      <h4 class="breadtitle">检测加固</h4>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>